<script>
import DataCard from './components/card'
import DataBlockTabs from './components/block/block_tabs'
import DataBlock from './components/block/block'
import Search from './components/search/search'
import GraphicList from '@/views/data/components/graphicList/graphicList'
import DataVideos from '@/views/data/components/videos/videos'
import WebView from '@/views/data/components/webView/webView'
import Atlas from '@/views/data/components/atlas/atlas'
import MarkerCluster from './components/maps/markerCluster'
import pdfViewer from './components/pdf-viewer/index'
import links from './components/links/index'
import { mapGetters,mapActions} from 'vuex'


export default {
  name: 'temp',
  components: {
    links,
    pdfViewer,
    'map-marker-cluster': MarkerCluster,
    GraphicList,
    DataVideos,
    WebView,
    Atlas,
    DataCard,
    Search,
    DataBlock,
    DataBlockTabs,
  },
  computed: {
    ...mapGetters({
      getDataMapByKey: 'schema/dataMapByKey',
      bigPictureEdit: 'bigPictureEditType',
    }),
  },
  data() {
    return {
      pdfUrls: [{src:'/mock/yzt/内蒙古自治区种业布局图放大版.pdf'}],
      pdfCurrentDepartment: {},
      pdfCurrentTitle: '',
    }
  },
  mounted() {
    this.loadDataSource({ name: 'nmdtItems', type: 'json' })
    this.loadDataSource({ name: 'yztResultDate', type: 'json' })
    this.loadDataSource({ name: 'gzdtData', type: 'json' }) //工作
    this.loadDataSource({ name: 'gzapData', type: 'json' }) //工作安排

    //this.loadDataSource({ name: 'categorys',params:{id:分类id就行了}})
  },
  methods: {
    ...mapActions({
      departmentsDataSelect:"schema/DepartmentsDataSelect",
      loadDataSource:'schema/LoadDataSource',
    }),
    pdfClick(e) {
      console.log(e)
      this.pdfUrls = []
      let pdfStatic = "/mock/yzt/" + e.fileContent
      console.log(pdfStatic)
      this.pdfUrls = [{src:pdfStatic}]

      this.pdfCurrentDepartment = e.deptName
      this.pdfCurrentTitle = e.title
    },
    getLoadJsonSource(name){
        this.LoadJsonSource(name).then((res)=>{
          return res
        })
    },
    gotoCard(divID) {
      gsap.to(window, { duration: 2, scrollTo: '#' + divID })
    },
    gotoUrl(url) {
      window.open(url)
    },
  },
}
</script>

<template>
  <fragment>
    <data-card name="内部管理" >
      <data-block name="精准监督" width="2000px">
        <web-view src="https://nm.dp.vc14.com/#/index"></web-view>
      </data-block>
<!--<data-block name="任务落实" width="1000px">-->


<!--  </data-block>-->


      <data-block name="成绩单">
        <web-view src="https://nm.ld.vc14.com/#/pages/index/index"></web-view>
      </data-block>

      <data-block name="马上办">
        <web-view src="https://nm.ld.vc14.com/#/pages/index/index"></web-view>
      </data-block>
      <data-block name="信号灯">
        <web-view src="https://nm.ld.vc14.com/#/pages/index/index"></web-view>
      </data-block>

      <data-block name="绩效考核">
        <web-view src="https://59.196.10.169:8002/#/"></web-view>
      </data-block>

<!--      <data-block name="工作调研">-->
<!--        <web-view src="https://nm.ld.vc14.com/#/pages/leader/rightaway/report"></web-view>-->
<!--      </data-block>-->
<!--
      <data-block name="工作调研">
        <web-view src="http://kf.cenggel.com/nmt/"></web-view>
      </data-block> -->
<data-block name="一张图" width="2000px">
        <div style="display: flex">
          <graphic-list
            :data="getDataMapByKey('yztResultDate')"
            :mapping="{ title: 'title', src: 'imageContent', date: 'updateTime', source: 'deptName' }"
            style="width: 480px; height: 1058px"
            @onClick="pdfClick"
          ></graphic-list>

          <pdf-viewer

            :title="pdfCurrentTitle"
            :srcList="pdfUrls"
            style="width: 1620px; height: 1058px"
          ></pdf-viewer>
        </div>
      </data-block>


    </data-card>

<!-- <data-card name="工作安排" id="news">-->

<!--      <data-block name="农牧动态">-->
<!--        <graphic-list :data="getDataMapByKey({name:'category',params:{id:2}})"></graphic-list>-->
<!--      </data-block>-->
<!--<data-block name="工作安排 流程 报告 成效成绩成果">-->
<!--        <graphic-list-->
<!--          :data="getDataMapByKey({name:'category',params:{id:2}})"-->
<!--          :mapping="{ title: 'title', src: 'images', date: 'created_at', source: 'author' }"-->
<!--        ></graphic-list>-->
<!--      </data-block>-->


<!--   <data-block name="工作简报">-->
<!--     <graphic-list-->
<!--       :data="getDataMapByKey({name:'category',params:{id:21}})"-->
<!--       :mapping="{ title: 'title', src: 'images', date: 'created_at', source: 'author' }"     ></graphic-list>-->

<!--   </data-block>-->

<!--&lt;!&ndash;      <data-block-tabs> </data-block-tabs>&ndash;&gt;-->
<!--      <data-block name="舆情监测">-->
<!--        <graphic-list :data="getDataMapByKey('nmdtItems')"></graphic-list>-->
<!--      </data-block>-->
<!-- <data-block name="政策解读">-->
<!--        <graphic-list-->
<!--          :mapping="{ title: 'title', src: 'images', date: 'created_at', source: 'author' }"-->
<!--          :data="getDataMapByKey(getDataMapByKey({name:'category',params:{id:22}}))"></graphic-list>-->
<!--      </data-block>-->


<!--   <data-block name="业务相关">-->
<!--     <graphic-list :data="getDataMapByKey(getDataMapByKey({name:'category',params:{id:2}}))"></graphic-list>-->
<!--   </data-block>-->

<!--   <data-block name="学习相关">-->
<!--     <graphic-list :data="getDataMapByKey(getDataMapByKey({name:'category',params:{id:1}}))"></graphic-list>-->
<!--   </data-block>-->
<!--    </data-card>-->



    <data-card name="数据平台">

      <data-block name="农牧大数据" width="3840px">
        <img
          src="./data/nmdsj.png"
          width="100%"
          height="1058"
          @click="gotoUrl('http://121.204.162.197:8088/resources/')"
        />
      </data-block>
      <data-block name="数据调度" width="1900px">
        <web-view src="https://nmt-sjcjxt.vc14.com:5000/tpcgfill/login?redirect=%2Findex"></web-view>
      </data-block>
      <data-block name="项目资金" width="3890px">
        <web-view src="https://zjdp.ldyw.vc14.com/"></web-view>
      </data-block>


    </data-card>



    <data-card name="数据应用" id="news">
      <data-block name="农垦集团--农机系统" width="1600px">
        <img
          src="./data/农垦集团--农机系统.png"
          width="100%"
          height="1058"
          @click="gotoUrl('http://bus.xiamentianyun.com:21003/configStorage.html')"
        />
      </data-block>


      <data-block name="三资" width="1600px">
        <img
          src="./data/sz.png"
          width="100%"
          height="1058"
          @click="gotoUrl('https://jtzcjg.moa.gov.cn/yygl/#/dashboard')"
        />
      </data-block>

      <data-block name="通辽" width="2400px">
        <web-view src="https://rpt.gtc315.cn/tongliao/page1_1_1"></web-view>
      </data-block>


      <data-block name="马铃薯晚疫病监测预警" width="1600px">
        <img
          src="~@/assets/images/data/mls.png"
          width="100%"
          height="1058"
          @click="gotoUrl('http://neimeng.chinablight.org/neimeng')"
        />
      </data-block>

      <data-block name="农垦集团--种植系统" width="1600px">
        <img
          src="./data/农垦集团--种植系统.png"
          width="100%"
          height="1058"
          @click="gotoUrl('http://bus.xiamentianyun.com:21002/configStorage.html')"
        />
      </data-block>

      <data-block name="龙头企业" width="1600px">
        <img
          src="./data/龙头企业.png"
          width="100%"
          height="1058"
          @click="gotoUrl('http://bi.xiamentianyun.com:18080/guideEnterprise?deptId=10')"
        />
      </data-block>

      <data-block name="智慧园区" width="1600px">
        <img
          src="./data/园区.png"
          width="100%"
          height="1058"
          @click="gotoUrl('http://bi.xiamentianyun.com:18080/park2?deptId=8')"
        />
      </data-block>
      <data-block name="视频中台" width="1600px">
        <img
          src="./data/视频中台.png"
          width="100%"
          height="1058"
          @click="gotoUrl('http://bi.xiamentianyun.com:18080/guideVideo?deptId=4')"
        />
      </data-block>
    </data-card>
  </fragment>
</template>


<style lang="scss">
.smooth-content {
  height: 100%;
  width: 100%;
}

.item9 {
  background: linear-gradient(to left, #2cd5ff, #2cd5ff) left top no-repeat,
    linear-gradient(to bottom, #2cd5ff, #2cd5ff) left top no-repeat,
    linear-gradient(to left, #2cd5ff, #2cd5ff) right top no-repeat,
    linear-gradient(to bottom, #2cd5ff, #2cd5ff) right top no-repeat,
    linear-gradient(to left, #2cd5ff, #2cd5ff) left bottom no-repeat,
    linear-gradient(to bottom, #2cd5ff, #2cd5ff) left bottom no-repeat,
    linear-gradient(to left, #2cd5ff, #2cd5ff) right bottom no-repeat,
    linear-gradient(to left, #2cd5ff, #2cd5ff) right bottom no-repeat;
  background-size: 1px 8px, 8px 1px, 1px 8px, 8px 1px;
  border: 1px solid #073f97;
  position: relative;
  display: inline-block;
  font-size: 16px;
  padding-top: 2px;
  margin-right: 16px;
  margin-top: 16px;
  text-align: center;
  width: 200px;
  height: 30px;

  &:hover {
    background-color: #2cd5ff;
    border: 1px solid #2cd5ff;
  }
}

.text-title {
  background: linear-gradient(0deg, #66ffff 0%, #c3f8b3 100%);
  -webkit-background-clip: text;
  /*将设置的背景颜色限制在文字中*/
  -webkit-text-fill-color: transparent;
  /*给文字设置成透明*/
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
}

.data-btn {
  width: 132px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(314deg, rgba(0, 171, 210, 0.6) 0%, rgba(0, 114, 167, 0.27) 100%);
  border: 1px solid;
  border-image: linear-gradient(136deg, rgba(12, 209, 220, 0.22), rgba(5, 164, 182, 0.73)) 1 1;
  transform: skewX(30deg);

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    background: linear-gradient(to top left, transparent 41%, #017396 50%, #03395cf0 51%, transparent 52%);
    pointer-events: none;
    /* 确保斜线不会干扰鼠标事件 */
  }

  div {
    transform: skewX(-30deg);
  }

  & > .text-title {
    color: #ffffff;
  }

  &:hover {
    background-color: #06a37e;
  }
}

.main {
  //background-color: rgb(4, 38, 55);
  //
  //background-image: url("https://p5.itc.cn/q_70/images01/20210830/ca6daed8c22543bc8712dbaa667b239b.jpeg");
  // background-size: cover ;
  background: transparent;
  color: #66ffff;
}

#background-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  //background: url('https://p5.itc.cn/q_70/images01/20210830/ca6daed8c22543bc8712dbaa667b239b.jpeg') no-repeat;
  background-size: cover;
}

.main-header-top {
  position: absolute;
  display: flex;
  justify-content: space-around;
  top: 0px;
  left: 0px;
  height: 68px;
  width: 100vw;
  z-index: 999;

  .main-header-nav {
    display: flex;
    align-items: center;

    & > div {
      margin-left: 1vw;
    }
  }
}

.main-header {
  // background-color: rgb(4, 38, 55);
  background-color: rgba(4, 38, 55, 0.5);
  /* Transparent background */
  //background-image: url("");
  background-size: cover;
  height: 70px;
  /* Footer height */
  display: flex;

  .main-header-bgimg {
    background: url('~@/assets/images/data/header-top.png') no-repeat;
    margin: 0 auto;
    width: 1018px;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      //color: #C3F8B3;
      background: linear-gradient(0deg, #66ffff 0%, #c3f8b3 100%);
      -webkit-background-clip: text;
      /*将设置的背景颜色限制在文字中*/
      -webkit-text-fill-color: transparent;
      /*给文字设置成透明*/
      font-size: 36px;
    }
  }
}

.main-footer {
  .main-footer-bgimg {
    //background-color: rgba(9, 60, 228, 0.5); /* Transparent background */
    background: url('~@/assets/images/data/footer.png') no-repeat;

    height: 94px;
    margin: 0 auto;
    width: 1920px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 6px;
    padding-left: 40px;

    & > div {
      margin-right: 16px;
    }
  }
}

.main-body {
  //height: calc(100vh - 100px - 72px);

  padding: 24px;
  padding-bottom: 0px;
  padding-top: 80px;
  scroll-behavior: smooth;

  //overflow-x: auto;
  //
  //background: transparent; /* Transparent background */
}

.scroll-container {
  display: flex;
  width: max-content;
  //overflow-x: auto;
}

.main-header-top,
.main-header,
.main-footer {
  position: fixed;
  width: 100%;
  z-index: 998;
  /* 确保它们在滚动内容之上 */
  left: 0;
}

.main-header-top {
  top: 0;
  z-index: 999;
  /* 确保它们在滚动内容之上 */
}

.main-header {
  top: 0;

  &--scrolled {
    height: 40px;
  }
}

.main-footer {
  bottom: 0;
  left: 0;
}

.dialog {
  width: 1178px;
  height: 666px;
  background: linear-gradient(180deg, #004146 0%, #000a25 10%, rgba(0, 10, 37, 0) 100%);
  border: 1px solid #66ffff;
  backdrop-filter: blur(13px);
  color: #ffffff;

  .el-dialog__title,
  .el-dialog__body {
    color: #ffffff;
  }
}

.el-select-dropdown {
  z-index: 9999999 !important;
}
</style>
